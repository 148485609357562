import isEqual from "lodash-es/isEqual";
import { useCallback, useState } from "react";
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FormikErrors, useFormik } from "formik";
import { Chip } from "@mui/material";
import { FooterContainer, InputContainer } from "./styled";

type FormValues = {
  email: string;
};

type Props = {
  open: boolean;
  initialEmails?: string[];
  loading?: boolean;
  onSubmit?: (emails: string[]) => void;
  onClose: () => void;
};

export const FinancialEmailsModal = ({
  open,
  initialEmails = [],
  loading,
  onSubmit,
  onClose,
}: Props) => {
  const [emails, setEmails] = useState<string[]>(initialEmails);

  const handleKeyDown = (e) => {
    if (e.key === "," || e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value;
      if (value.trim() !== "") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value.trim())) {
          formik.setTouched({ email: true });
          formik.setErrors({
            email: "Por favor, insira um endereço de email válido.",
          });
        } else {
          setEmails((state) => [...state, value.trim()]);
          formik.setTouched({ email: false });
          formik.setFieldValue("email", "");
          formik.setErrors({});
        }
      }
    }
  };

  const handleRemoveTag = useCallback((index: number) => {
    setEmails((state) => {
      const newEmails = [...state];
      newEmails.splice(index, 1);
      return newEmails;
    });
  }, []);

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validate: ({ email }) => {
      const errors: FormikErrors<FormValues> = {};

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email.trim() !== "" && !emailRegex.test(email.trim())) {
        errors.email = "Por favor, insira um endereço de email válido.";
      }

      if (emails.length === 0) {
        errors.email = "Por favor, insira pelo menos um e-mail";
      }

      return errors;
    },
    onSubmit: () => {
      onSubmit?.(emails);
    },
  });

  return (
    <Modal.Root size="sm" open={open} onClose={onClose}>
      <>
        <Modal.Header
          title="E-mail financeiro"
          description="Insira os e-mails para os quais você pretende receber notas fiscais e faturas."
        />
        <Modal.Content>
          <div>
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Preencha o e-mail principal e caso necessário adicione outros
            </Typography>

            <InputContainer>
              <TextField
                id="email"
                name="email"
                label="E-mail (pressione enter para adicionar)"
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                onKeyDown={handleKeyDown}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={{ width: "100%" }}
              />
              {emails.map((email, index) => (
                <Chip
                  key={`${email}-${index}`}
                  label={email}
                  onDelete={() => handleRemoveTag(index)}
                  deleteIcon={<Icons name="IconX" size={18} />}
                />
              ))}
            </InputContainer>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <FooterContainer>
            <Button
              variant="primary"
              size="large"
              loading={loading}
              disabled={isEqual(initialEmails, emails)}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Confirmar
            </Button>

            <LinkButton variant="primary" onClick={onClose}>
              Cancelar
            </LinkButton>
          </FooterContainer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
