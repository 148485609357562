import {
  Loader,
  Pagination,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  useReactTable,
  getCoreRowModel,
  TableOptions,
  flexRender,
  PaginationState,
} from "@tanstack/react-table";
import React, { useEffect } from "react";
import * as SC from "./styles";
import EmptyFilteredTableSVG from "../../assets/empty_filtered_table.svg";
import EmptyTableSVG from "../../assets/empty_table.svg";
import customFilters from "./customFilters";

export type TableRef = {
  resetPageIndex: () => void;
};

export type TableProps<Columns> = {
  columns: Array<
    TableOptions<Columns>["columns"][number] & {
      isSticky?: true;
    }
  >;
  data: TableOptions<Columns>["data"];
  initialState: { pagination: PaginationState };
  onPaginationChange?: (_pagination: PaginationState) => void;
  loading: boolean;
  pageCount?: number;
  totalCount?: number;
  pageSizeOptions?: { value: number; label: string }[];
  emptyState: {
    emptyText: string;
    filteredEmptyText: string;
    isFiltered: boolean;
  };
  tableRef?: React.Ref<TableRef>;
};

export default function Table<Columns>(props: TableProps<Columns>) {
  const [pagination, setPagination] = React.useState<PaginationState>(
    props.initialState.pagination
  );

  useEffect(() => {
    props.onPaginationChange?.(pagination);
  }, [pagination]);

  const table = useReactTable<Columns>({
    columns: props.columns,
    data: props.data,
    getCoreRowModel: getCoreRowModel(),
    initialState: props.initialState,
    filterFns: customFilters,
  });

  React.useImperativeHandle<TableRef, TableRef>(props.tableRef, () => ({
    resetPageIndex: () => setPagination({ ...pagination, pageIndex: 0 }),
  }));

  const RenderedTable = () => {
    // Loading
    if (props.loading)
      return (
        <SC.EmptyTable>
          <Loader variant="primary" size="medium" />
        </SC.EmptyTable>
      );

    // Empty
    if (!props.data?.length)
      return (
        <SC.EmptyTable>
          {props.emptyState.isFiltered ? (
            <EmptyFilteredTableSVG />
          ) : (
            <EmptyTableSVG />
          )}
          <Typography variant="body3">
            {props.emptyState.isFiltered
              ? props.emptyState.filteredEmptyText
              : props.emptyState.emptyText}
          </Typography>
        </SC.EmptyTable>
      );

    let allHeaderElements: Array<number> = [];
    return (
      <div className="root">
        <SC.Table>
          <SC.TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <SC.Th
                    sticky={header.column.columnDef["isSticky"]}
                    ref={(element: HTMLTableHeaderCellElement) => {
                      if (element) {
                        const { headers } = headerGroup;
                        if (allHeaderElements.length === 0) {
                          const elements: HTMLTableHeaderCellElement[] =
                            Array.prototype.slice.call(
                              element.parentElement!.children
                            );
                          allHeaderElements = elements.map(
                            ({ clientWidth }) => clientWidth
                          );
                        }

                        if (
                          index <= headers.length / 2 - 1 &&
                          header.column.columnDef["isSticky"]
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= headers.length / 2 + 1 &&
                          header.column.columnDef["isSticky"]
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </SC.Th>
                ))}
              </tr>
            ))}
          </SC.TableHeader>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <SC.TableCell
                    sticky={cell.column.columnDef["isSticky"]}
                    ref={(element: HTMLTableDataCellElement) => {
                      if (element) {
                        const rowsLength = row.getVisibleCells().length;
                        if (
                          index <= rowsLength / 2 - 1 &&
                          cell.column.columnDef["isSticky"]
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= rowsLength / 2 + 1 &&
                          cell.column.columnDef["isSticky"]
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </SC.TableCell>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </SC.Table>
      </div>
    );
  };

  return (
    <>
      <SC.TableContainer>
        {/* <SC.TableContainer ref={headerRef}> */}
        <RenderedTable />
      </SC.TableContainer>
      {props.pageCount !== undefined && (
        <SC.PaginationContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.StyledSelectField
              value={pagination.pageSize}
              options={props.pageSizeOptions}
              onSelectChange={(_event, { value }) => {
                setPagination({
                  pageIndex: 0,
                  pageSize: value,
                });
              }}
              aria-label="Alterar quantidade de itens por página"
            />
            {props.totalCount !== undefined && (
              <SC.Counter>{`${
                pagination.pageIndex * pagination.pageSize + 1
              }-${Math.min(
                (pagination.pageIndex + 1) * pagination.pageSize,
                props.totalCount
              )} de ${props.totalCount} itens`}</SC.Counter>
            )}
          </div>
          <Pagination
            page={pagination.pageIndex + 1}
            onChange={(_event, page) => {
              setPagination({
                pageIndex: page - 1,
                pageSize: pagination.pageSize,
              });
            }}
            count={props.pageCount}
          />
        </SC.PaginationContainer>
      )}
    </>
  );
}
