import { segmentEventTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

const track = ({
  name,
  params,
  identify,
}: {
  name: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}) => {
  segmentEventTracking({
    name,
    module: "hros_finance",
    businessUnit: "finance",
    params,
  });
};

export { track };
