import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;

  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};

  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.xs};
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

export const EmailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  align-items: flex-start;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledTag = styled(Tag)`
  cursor: auto;
  padding: ${({ theme }) => `0 ${theme.spacings.xs4}`};
  pointer-events: none;
  white-space: pre;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
