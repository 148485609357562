/**
 * Groups URL search parameters by key, grouping specified keys into arrays.
 *
 * @param {URLSearchParams} searchParams - The URL search parameters to group.
 * @param {string[]} paramsToGroup - An array of parameter keys to group into arrays.
 * @returns {Record<string, string | string[]>} - An object containing the grouped parameters.
 *
 * @example
 * groupParamsByKey(new URLSearchParams("foo=1&foo=2&bar=3&baz=4"), ["foo", "bar"]);
 * // Result:
 * // {
 * //   foo: ["1", "2"],
 * //   bar: ["3"],
 * //   baz: "4"
 * // }
 */
export const groupParamsByKey = (
  searchParams: URLSearchParams,
  paramsToGroup: string[]
): Record<string, string | string[]> =>
  [...searchParams.entries()].reduce((acc, [key, val]) => {
    const shouldGroup = paramsToGroup.includes(key);
    if (shouldGroup) {
      acc[key] = acc[key] ? [...acc[key], val] : [val];
    } else {
      acc[key] = val;
    }
    return acc;
  }, {});
