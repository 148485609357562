import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root, {
  EmbeddedFinanceBillsPage,
  EmbeddedSettingsPage,
} from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here .
    return <div>ERRO</div>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export { EmbeddedFinanceBillsPage, EmbeddedSettingsPage };
