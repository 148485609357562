import Table from "../../../../components/TableV2";
import { billsColumns, pageSizeOptions } from "./tableSetup";

const BillsTable = (props) => (
  <Table
    emptyState={{
      isFiltered: false,
      emptyText: "",
      filteredEmptyText: "",
    }}
    pageSizeOptions={pageSizeOptions}
    columns={billsColumns}
    {...props}
  />
);

export default BillsTable;
