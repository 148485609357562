import { BrowserRouter, Route, Routes } from "react-router-dom";
import SettingsPage from "../pages/Settings";
import BillsPage from "../pages/Bills";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/finance/bills" element={<BillsPage />} />
        <Route path="/finance/settings" element={<SettingsPage />} />
      </Routes>
    </BrowserRouter>
  );
};
export default AppRouter;
