import { z } from "zod";

const EnvSchema = z.object({
  STAGE: z.enum(["production", "staging", "development"]),
});

const env = EnvSchema.parse({
  STAGE: process.env.STAGE,
});

export default env;
