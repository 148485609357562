import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

import { dispatchToast } from "../../utils/dispatchEvents";
import { trpc } from "../../api/client";

export const useFindCustomerFinancialEmails = () => {
  const { selectedCompany } = useSelectedCompany();
  const [emails, setEmails] = useState<string[]>([]);
  const { mutate, isLoading } = trpc.customer.findCustomer.useMutation({
    onSuccess: (data) => {
      setEmails(data.emails);
    },
    onError: () => {
      dispatchToast({
        content: "Ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  useEffect(() => {
    mutate({
      companyId: selectedCompany.id,
    });
  }, [mutate, selectedCompany.id]);

  return {
    emails,
    setEmails,
    extraEmailsSize: emails.length > 1 ? emails.length - 1 : 0,
    isLoading,
  };
};
