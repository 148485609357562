import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TableHeaderContainer, TagStyled } from "./styled";

export const TableHeader = ({ totalCount, loading }) => {
  return (
    <TableHeaderContainer>
      <Typography variant="headline8">Histórico de cobranças</Typography>
      {totalCount && (
        <TagStyled disabled={true} variant="gray" loading={loading}>
          {`${totalCount} itens`}
        </TagStyled>
      )}
    </TableHeaderContainer>
  );
};
