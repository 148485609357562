import styled from "styled-components";

interface NoWrapProps {
  width?: number;
}

const NoWrap = styled.div<NoWrapProps>`
  white-space: nowrap;
  ${({ width }) => width !== undefined && `width: ${width}px`}
`;

export { NoWrap };
