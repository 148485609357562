import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Grid, Skeleton } from "@mui/material";
import { useState } from "react";

import { trpc } from "../../api/client";
import { dispatchToast } from "../../utils/dispatchEvents";
import { FinancialEmailsModal } from "./components/FinancialEmailsModal";
import { useFindCustomerFinancialEmails } from "./hooks";
import {
  StyledBox,
  StyledTag,
  StyledText,
  StyledTitle,
  PageContainer,
  EmailsContainer,
} from "./styled";

const SettingsPage = () => {
  const { selectedCompany } = useSelectedCompany();
  const { emails, setEmails, extraEmailsSize, isLoading } =
    useFindCustomerFinancialEmails();

  const [openEmailModal, setOpenEmailModal] = useState(false);

  const updateCustomerFinancialEmails =
    trpc.customer.updateCustomerFinancialEmails.useMutation({
      onSuccess: (data) => {
        dispatchToast({
          type: "success",
          content: "E-mails atualizados com sucesso",
        });
        setEmails(data.emails);
        setOpenEmailModal(false);
      },
      onError: () => {
        dispatchToast({
          content:
            "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
          type: "error",
        });
      },
    });

  const handleUpdateEmails = (financialEmails: string[]) => {
    updateCustomerFinancialEmails.mutate({
      companyId: selectedCompany.id,
      emails: financialEmails,
    });
  };

  return (
    <>
      <PageContainer>
        <StyledTitle variant="headline8" style={{ marginBottom: "24px" }}>
          Configurações
        </StyledTitle>

        <Grid container spacing={3}>
          <Grid item md={6} sm={6} xs={12}>
            <StyledBox>
              <div>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  E-mail financeiro
                </StyledTitle>
                <EmailsContainer>
                  {isLoading ? (
                    <Skeleton width="100%" height="24px" />
                  ) : (
                    <>
                      <StyledText variant="body3" style={{ fontWeight: 600 }}>
                        {emails[0]}
                      </StyledText>
                      {!extraEmailsSize ? (
                        <div />
                      ) : (
                        <StyledTag variant="gray">+{extraEmailsSize}</StyledTag>
                      )}
                    </>
                  )}
                </EmailsContainer>
              </div>
              <LinkButton
                disabled={isLoading}
                variant="primary"
                style={{ alignSelf: "flex-start" }}
                onClick={() => {
                  setOpenEmailModal(true);
                }}
              >
                Editar
              </LinkButton>
            </StyledBox>
          </Grid>
        </Grid>
      </PageContainer>

      {openEmailModal && (
        <FinancialEmailsModal
          open={openEmailModal}
          initialEmails={emails}
          loading={updateCustomerFinancialEmails.isLoading}
          onSubmit={handleUpdateEmails}
          onClose={() => setOpenEmailModal(false)}
        />
      )}
    </>
  );
};

export default SettingsPage;
