import { BrowserRouter } from "react-router-dom";
import AppProviders from "./root.providers";
import AppRouter from "./routes";

import BillsPage from "./pages/Bills";
import SettingsPage from "./pages/Settings";

import "../src/i18n";

export default function Root() {
  return (
    <AppProviders>
      <AppRouter />
    </AppProviders>
  );
}

export const EmbeddedFinanceBillsPage = () => (
  <AppProviders>
    <BrowserRouter>
      <BillsPage />
    </BrowserRouter>
  </AppProviders>
);

export const EmbeddedSettingsPage = () => (
  <AppProviders>
    <BrowserRouter>
      <SettingsPage />
    </BrowserRouter>
  </AppProviders>
);
