import { SearchField as UISearchField } from "@flash-tecnologia/hros-web-ui-v2";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";

const DEBOUNCE_TIME = 500;

const StyledSearchField = styled(UISearchField)`
  margin-bottom: ${(p) => p.theme.spacings.s};
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 150px;
  }
`;

export const SearchField = ({ value = "", onChange, ...props }) => {
  const debouncedOnChange = useDebouncedCallback((value) => {
    onChange(value);
  }, DEBOUNCE_TIME);

  return (
    <StyledSearchField
      role="search"
      label="Buscar por número do pedido, número da nota ou do boleto"
      value={value.trim()}
      onChange={(e) => debouncedOnChange(e.target.value.trim())}
      {...props}
    />
  );
};
