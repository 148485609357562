import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  padding: ${({ theme }) => theme.spacings.xs4};
  gap: ${({ theme }) => theme.spacings.xs4};
`;
