import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { useMemo } from "react";
import { track } from "../../../../utils/tracking/segment";
import { Action } from "./styled";
import { ActionOption } from "./types";

enum PaymentTypes {
  BALANCE = "BALANCE",
  BILLET = "BILLET",
  CARD = "CARD",
  PIX = "PIX",
}

const createActionOption = ({
  key,
  icon,
  label,
  link,
  tracking,
  iconProps = {},
}): ActionOption => ({
  key,
  children: (
    <Action>
      <Icons name={icon} fill="transparent" aria-hidden="true" {...iconProps} />{" "}
      {label}
    </Action>
  ),
  onClick: () => {
    track({ name: tracking });
    openLinkInNewTab(link);
  },
});

const getInvoiceActions = (invoices) => {
  const options: ActionOption[] = [];

  if (invoices?.[0]?.link) {
    const { id, link } = invoices[0];
    const downloadInvoiceOption: ActionOption = createActionOption({
      key: `invoice-${id}-link`,
      icon: "IconFileDownload",
      label: "Baixar Nota Fiscal",
      link,
      tracking: "bills_download_invoice_clicked",
    });
    options.push(downloadInvoiceOption);
  }

  if (invoices?.[0]?.xmlLink) {
    const { id, xmlLink } = invoices[0];
    const downloadXMLOption: ActionOption = createActionOption({
      key: `invoice-${id}-xml-link`,
      icon: "IconBookDownload",
      label: "Baixar XML",
      link: xmlLink,
      tracking: "bills_download_invoice_xml_clicked",
    });
    options.push(downloadXMLOption);
  }

  return options;
};

const getPaymentActions = (payments) => {
  const options: ActionOption[] = [];

  if (payments?.[0]?.link) {
    const { id, link, type } = payments[0];
    let downloadPaymentOption: ActionOption;

    switch (type) {
      case PaymentTypes.PIX:
        downloadPaymentOption = createActionOption({
          key: `payment-${id}`,
          icon: "Pix",
          iconProps: { strokeWidth: "4" },
          label: "Baixar chave PIX",
          link,
          tracking: "bills_download_pix_key_clicked",
        });
        break;
      default:
        downloadPaymentOption = createActionOption({
          key: `payment-${id}`,
          icon: "IconBarcode",
          label: "Baixar Boleto",
          link,
          tracking: "bills_download_billet_clicked",
        });
        break;
    }

    options.push(downloadPaymentOption);
  }

  return options;
};

const getDebitNoteActions = (debitNote) => {
  const options: ActionOption[] = [];
  if (debitNote?.link) {
    const { link } = debitNote;
    const downloadDebitNoteOption: ActionOption = createActionOption({
      key: `debitNote-${link}`,
      icon: "IconClipboard",
      label: "Baixar Nota de Débito",
      link,
      tracking: "bills_download_debit_note_clicked",
    });

    options.push(downloadDebitNoteOption);
  }

  return options;
};

const openLinkInNewTab = (link: string) =>
  window.open(link, "_blank", "noreferrer");

const ActionsMenu = ({ payments, invoices, debitNote }) => {
  const options: ActionOption[] = useMemo(
    () => [
      ...getInvoiceActions(invoices),
      ...getPaymentActions(payments),
      ...getDebitNoteActions(debitNote),
    ],
    [payments, invoices, debitNote]
  );

  return (
    <Menu
      type={"select"}
      options={options}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 0, horizontal: -210 }}
      disabled={!options.length}
    >
      <IconButton
        size="medium"
        variant="line"
        icon="IconDotsVertical"
        aria-label="Menu de ações do item"
      />
    </Menu>
  );
};

export default ActionsMenu;
