import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient, trpc, trpcClient } from "./api/client";

const AppProviders = ({ children }) => (
  <trpc.Provider client={trpcClient} queryClient={queryClient}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>{children}</ThemeProvider>
    </QueryClientProvider>
  </trpc.Provider>
);

export default AppProviders;
