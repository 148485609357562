import { Tag as UITag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Tag = styled(UITag)((props) => ({
  padding: `${props.theme.spacings.xs5} ${props.theme.spacings.xs3}`,
  border: 0,
}));

const getTagVariant = (status) =>
  ({
    PAID: "green",
    CANCELLED: "pink",
    PENDING_PAYMENT: "blue",
  }[status]);

const getStatusLabel = (status) =>
  ({
    PAID: "Pago",
    CANCELLED: "Cancelado",
    PENDING_PAYMENT: "Em espera",
  }[status]);

export const StatusTag = ({ status, ...props }) => (
  <Tag variant={getTagVariant(status)} disabled hasLeftDotIcon {...props}>
    {getStatusLabel(status)}
  </Tag>
);
