import { DateTime } from "luxon";

export function formatDateTime(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL/yy' às 'HH:mm");
}

export function formatDate(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL/yyyy");
}

export function formatDateWithoutYear(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL");
}
