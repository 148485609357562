import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { dispatchToast } from "../../utils/dispatchEvents";
import useGetBills, {
  ARRAY_FILTERS,
  Filter,
  sanitizeFilter,
} from "./dataSource/useGetBills";
import BillsTable from "./components/BillsTable";
import { TableHeader } from "./components/Header";
import { SearchField } from "./components/SearchField";
import { TagFilters } from "./components/TagFilters";
import { TableContainer } from "./styled";
import { groupParamsByKey } from "./utils";
import { TableRef } from "@frontend/components/TableV2";

const getInitialFilter = (searchParams: URLSearchParams) => {
  return sanitizeFilter(groupParamsByKey(searchParams, ARRAY_FILTERS));
};

const BillsPage = () => {
  const tableRef = React.useRef<TableRef>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    bills,
    isLoading,
    error,
    pageCount,
    totalCount,
    filter,
    setFilter,
    pagination,
    setPagination,
  } = useGetBills(getInitialFilter(searchParams));

  const setFilterField = (field: keyof Filter) => (value) => {
    setFilter((previousFilter) => ({
      ...previousFilter,
      offset: 0,
      [field]: value,
    }));
    tableRef.current?.resetPageIndex();
  };

  useEffect(() => {
    setSearchParams(sanitizeFilter(filter));
  }, [setSearchParams, filter]);

  if (error) {
    dispatchToast({
      type: "error",
      content: error.message,
    });
  }

  return (
    <TableContainer>
      <TableHeader totalCount={totalCount} loading={isLoading} />
      <SearchField
        onChange={setFilterField("textSearch")}
        value={filter.textSearch}
      />
      <TagFilters filter={filter} setFilterField={setFilterField} />
      <BillsTable
        loading={isLoading}
        data={bills}
        initialState={{
          pagination,
        }}
        pageCount={pageCount}
        totalCount={totalCount}
        onPaginationChange={setPagination}
        tableRef={tableRef}
      />
    </TableContainer>
  );
};

export default BillsPage;
