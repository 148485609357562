import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TableContainer = styled.div`
  font-family: "Nunito";
  color: ${(p) => p.theme.colors.neutral[40]};
  border: 1px solid ${(p) => p.theme.colors.neutral[90]};
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const EmptyTable = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHeader = styled.thead`
  width: 100%;
  th {
    padding: 24px;
    text-align: left;
  }
`;

type CellProps = {
  sticky?: boolean;
};

export const Th = styled.th<CellProps>`
  position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
  background-color: ${(p) => p.theme.colors.neutral[95]};
  &:after {
    top: 0;
    left: -30px;
    width: 30px;
    height: 100%;
    content: "";
    position: absolute;
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }
`;

export const TableCell = styled.td<CellProps>`
  padding: 24px;
  position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
  text-align: left;
  background-color: ${(p) => p.theme.colors.neutral[100]};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral[90]};
  &:after {
    top: 0;
    left: -30px;
    width: 30px;
    height: 100%;
    content: "";
    position: absolute;
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }
`;

export const StyledSelectField = styled(SelectField)`
  z-index: 1 !important;
  width: 150px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  .MuiFilledInput-root {
    border-radius: 200px !important;
    border: 1px solid ${(p) => p.theme.colors.neutral[90]} !important;
    padding: 10px 16px;
  }

  .MuiSelect-select {
    padding: 9px 8px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-neutral-dark4);

    &:focus {
      background-color: transparent;
    }
  }
`;

export const Counter = styled.div`
  display: flex;
  color: var(--neutral-neutral-50, #83727d);
  text-align: left;
  margin-left: 20px;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
`;
